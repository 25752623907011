<script>
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      valid: true,
      form: {},

      validations: {
        id: [(v) => !!v || 'Parâmetro é obrigatório'],
        info: [(v) => !!v || 'Informação é obrigatório'],
        value: [(v) => !!v || 'Valor é obrigatório'],
      },
    };
  },

  async created() {
    if (this.id !== 'new' && this.id !== 'novo') {
      this.form = await this.fetch();
      // delete this.form.id;
      delete this.form.__typename;
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return false;
      if (this.id === 'new' || this.id === 'novo') this.insert();
      else this.update();
    },

    async insert() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($param: params_insert_input!) {
              insert_params_one(object: $param) {
                id
              }
            }
          `,
          variables: {
            param: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/parametros');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!, $param: params_set_input!) {
              update_params_by_pk(pk_columns: { id: $id }, _set: $param) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            param: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/parametros');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query Params($id: String!) {
              param: params_by_pk(id: $id) {
                id
                info
                value
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.param;
      } catch (e) {
        console.error(e);
      }
    },

    toUpperCase(val) {
      if (val) return val.toUpperCase();
      return val
    }
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Parâmetro</h1>

    <v-card class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.id"
              label="Parâmetro"
              :rules="validations.name"
              disabled
              outlined
              persistent-placeholder
              @change="(val) => form.id = toUpperCase(val)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.info"
              label="Informação"
              outlined
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.value"
              label="Valor"
              type="number"
              outlined
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-spacer />
          <v-btn color="primary" plain class="mr-2" to="/parametros">Cancelar</v-btn>
          <v-btn color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
